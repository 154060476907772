export const menus = {
  pmgroup: [
    'home',
    'contacts',
    'companies',
    'practices',
    'schedule',
    'contact-center',
    'email:email-templates',
    'email:mail-logs',
    'registry',
    'offers',
    'prospects',
    'report',
    'estimates',
    'misc:visure',
    'settings:user',
    'settings:assign-contacts',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:practices-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
    'chat',
    'valorizza',
  ],
  maxcredit: [
    'home',
    'contacts',
    'companies',
    'practices',
    'schedule',
    'contact-center',
    'email:email-templates',
    'email:mail-logs',
    'registry',
    'offers',
    'prospects',
    'report',
    'estimates',
    'misc:visure',
    'settings:user',
    'settings:assign-contacts',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:practices-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
    'chat',
    'valorizza',
  ],
  vigilanza: [
    'home',
    'contacts',
    'companies',
    'schedule',
    'allprojects',
    'email:mail-logs',
    'portierato:projects',
    'portierato:user-projects',
    'portierato:user-plannings',
    'pulizie:projects',
    'pulizie:user-projects',
    'pulizie:user-plannings',
    'pulizieNase:projects',
    'pulizieNase:user-projects',
    'pulizieNase:user-plannings',
    'pulizieAbru:projects',
    'pulizieAbru:user-projects',
    'pulizieAbru:user-plannings',
    'pulizieBasi:projects',
    'pulizieBasi:user-projects',
    'pulizieBasi:user-plannings',
    'pulizieCala:projects',
    'pulizieCala:user-projects',
    'pulizieCala:user-plannings',
    'pulizieCamp:projects',
    'pulizieCamp:user-projects',
    'pulizieCamp:user-plannings',
    'pulizieEmil:projects',
    'pulizieEmil:user-projects',
    'pulizieEmil:user-plannings',
    'pulizieLazi:projects',
    'pulizieLazi:user-projects',
    'pulizieLazi:user-plannings',
    'pulizieLigu:projects',
    'pulizieLigu:user-projects',
    'pulizieLigu:user-plannings',
    'pulizieLomb:projects',
    'pulizieLomb:user-projects',
    'pulizieLomb:user-plannings',
    'pulizieMarc:projects',
    'pulizieMarc:user-projects',
    'pulizieMarc:user-plannings',
    'pulizieMoli:projects',
    'pulizieMoli:user-projects',
    'pulizieMoli:user-plannings',
    'puliziePiem:projects',
    'puliziePiem:user-projects',
    'puliziePiem:user-plannings',
    'puliziePugl:projects',
    'puliziePugl:user-projects',
    'puliziePugl:user-plannings',
    'pulizieSard:projects',
    'pulizieSard:user-projects',
    'pulizieSard:user-plannings',
    'pulizieTosc:projects',
    'pulizieTosc:user-projects',
    'pulizieTosc:user-plannings',
    'pulizieTren:projects',
    'pulizieTren:user-projects',
    'pulizieTren:user-plannings',
    'pulizieVene:projects',
    'pulizieVene:user-projects',
    'pulizieVene:user-plannings',
    'management_check:operations',
    'management_check:costs',
    'management_check:vacations',
    'management_check:machines',
    'management_check:uniforms',
    'settings:user',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
  ],
  vigilanzatest: [
    'home',
    'contacts',
    'companies',
    'schedule',
    'allprojects',
    'email:mail-logs',
    'portierato:projects',
    'portierato:user-projects',
    'portierato:user-plannings',
    'pulizie:projects',
    'pulizie:user-projects',
    'pulizie:user-plannings',
    'pulizieNase:projects',
    'pulizieNase:user-projects',
    'pulizieNase:user-plannings',
    'pulizieAbru:projects',
    'pulizieAbru:user-projects',
    'pulizieAbru:user-plannings',
    'pulizieBasi:projects',
    'pulizieBasi:user-projects',
    'pulizieBasi:user-plannings',
    'pulizieCala:projects',
    'pulizieCala:user-projects',
    'pulizieCala:user-plannings',
    'pulizieCamp:projects',
    'pulizieCamp:user-projects',
    'pulizieCamp:user-plannings',
    'pulizieEmil:projects',
    'pulizieEmil:user-projects',
    'pulizieEmil:user-plannings',
    'pulizieLazi:projects',
    'pulizieLazi:user-projects',
    'pulizieLazi:user-plannings',
    'pulizieLigu:projects',
    'pulizieLigu:user-projects',
    'pulizieLigu:user-plannings',
    'pulizieLomb:projects',
    'pulizieLomb:user-projects',
    'pulizieLomb:user-plannings',
    'pulizieMarc:projects',
    'pulizieMarc:user-projects',
    'pulizieMarc:user-plannings',
    'pulizieMoli:projects',
    'pulizieMoli:user-projects',
    'pulizieMoli:user-plannings',
    'puliziePiem:projects',
    'puliziePiem:user-projects',
    'puliziePiem:user-plannings',
    'puliziePugl:projects',
    'puliziePugl:user-projects',
    'puliziePugl:user-plannings',
    'pulizieSard:projects',
    'pulizieSard:user-projects',
    'pulizieSard:user-plannings',
    'pulizieTosc:projects',
    'pulizieTosc:user-projects',
    'pulizieTosc:user-plannings',
    'pulizieTren:projects',
    'pulizieTren:user-projects',
    'pulizieTren:user-plannings',
    'pulizieVene:projects',
    'pulizieVene:user-projects',
    'pulizieVene:user-plannings',
    'management_check:operations',
    'management_check:costs',
    'management_check:vacations',
    'management_check:machines',
    'management_check:uniforms',
    'settings:user',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
  ],
  deluca: [
    'home',
    'contacts',
    'companies',
    'practices',
    'schedule',
    'contact-center',
    'email:email-templates',
    'email:mail-logs',
    'report',
    'estimates',
    'misc:visure',
    'settings:user',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:practices-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'valorizza',
  ],
  vera: [
    'home',
    'contacts',
    'companies',
    'schedule',
    'email:mail-logs',
    'management_check:operations',
    'management_check:projects',
    'management_check:costs',
    'management_check:vacations',
    'industry_4_0',
    'management_check:machines',
    'management_check:external-companies',
    'management_check:user-projects',
    'management_check:user-plannings',
    'settings:collab',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
  ],
  hrss: [
    'home',
    'contacts',
    'companies',
    'schedule',
    'management_check:projects',
    'management_check:costs',
    'management_check:vacations',
    'management_check:user-projects',
    'management_check:user-plannings',
    'management_check:machines',
    'settings:collab',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
  ],
  securcontrol: [
    'home',
    'contacts',
    'companies',
    'schedule',
    'email:mail-logs',
    'management_check:operations',
    'management_check:projects',
    'management_check:costs',
    'management_check:vacations',
    'industry_4_0',
    'management_check:machines',
    'management_check:uniforms',
    'management_check:user-projects',
    'management_check:user-plannings',
    'settings:user',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
  ],
  clemps: [
    'home',
    'contacts',
    'companies',
    'practices',
    'schedule',
    'contact-center',
    'email:email-templates',
    'email:mail-logs',
    'registry',
    'offers',
    'prospects',
    'report',
    'estimates',
    'industry_4_0',
    'misc:visure',
    'settings:user',
    'settings:user-settings',
    'settings:contact-settings',
    'settings:practices-settings',
    'settings:schedule-settings',
    'settings:email-settings',
    'settings:assignment-settings',
    'management_check:operations',
    'management_check:projects',
    'management_check:costs',
    'management_check:vacations',
    'management_check:machines',
    'management_check:uniforms',
    'management_check:user-projects',
    'management_check:user-plannings',
  ],
};
